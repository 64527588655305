html {
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.App {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-wrapper {
  max-width: 25%;
}

.logo-wrapper img {
  width: 100%;
  height: auto;
}
